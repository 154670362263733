/* You can add global styles to this file, and also import other style files */
.avatar_edit{
    width: 200px;
    margin-bottom: 10px;
}

.avatar_nav{
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 70%;
}

.card-header a{
    color: #1ABC9C;
}

.post-image{
    height: 200px;
    overflow: hidden;
}

footer{
    color: lightgray;
}